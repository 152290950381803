import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import Footer from 'components/molecules/Footer'
import Logo from 'components/atoms/Logo'
import Button from 'components/atoms/Button'
import { NextPageContext } from 'next'
interface Props {
  statusCode: number
}

function Error({ statusCode }: Props) {
  return (
    <main className="page-container">
      <section className="main">
        <div className="column -left">
          <h1 className="title">
            <Logo />
          </h1>
          <div className="row">
            <h2 className="title -h2 -huge">Ops!</h2>
            <p className="paragraph -xs">
              Não conseguimos achar a página que está procurando :/
            </p>
            <Link href="https://plusdin.com.br/">
              <a>
                <Button className="button -xxs">Ir para a Home</Button>
              </a>
            </Link>
          </div>
          <div className="row">
            <p className="paragraph -xs">
              Que tal aproveitar para descrobrir qual é o cartão de crédito
              ideal para você?
            </p>
            <Link href="https://responda.plusdin.com.br/plusdin-recomendador-cartao-de-credito/index.html">
              <a>
                <Button type="outline" className="button -md -icon -icon-right">
                  FAZER O QUIZ
                  <span aria-hidden className="fas fa-external-link-alt"></span>
                </Button>
              </a>
            </Link>
          </div>
          <div className="row">
            {/* <h3 className="title -h3 -xxs">Alguns links úteis</h3> */}
          </div>
        </div>
        <div className="column -right">
          <div className="row">
            <Image
              src={`/assets/images/vectors/${statusCode}.svg`}
              layout="fill"
              objectFit="cover"
            />
          </div>
        </div>
      </section>
      <Footer className="footer">
        <p className="paragraph">
          © 2020 - Plusdin - Todos os direitos reservados
        </p>
      </Footer>
    </main>
  )
}

Error.getInitialProps = ({ res, err }: NextPageContext) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404
  return { statusCode }
}

export default Error
