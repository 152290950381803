import styled from 'styled-components'

export const Footer = styled.footer`
  background-color: var(--brand-neutral-80);
  padding-top: var(--space-inset-md);
  padding-left: var(--space-inset-md);
  padding-right: var(--space-inset-md);
  padding-bottom: var(--space-inset-xs);
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;

  .paragraph {
    color: var(--brand-basic-white);
    font-size: var(--brand-font-size-xs);
  }
`
