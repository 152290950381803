import { ReactNode } from 'react'

export type Props = {
  value?: string
  className?: string
  size?: string
  type?: string
  enabled?: boolean
  children?: ReactNode
  clickCallback?: (e: React.MouseEvent<Element, MouseEvent>) => void
}

const Button = ({
  value = '',
  className = '',
  size = 'xxs',
  type = 'primary',
  enabled = true,
  children,
  clickCallback = () => null,
  ...attrs
}: Props) => (
  <>
    <button
      className={`button -${size} -${type} ${
        !enabled && '-disabled'
      } ${className}`}
      {...attrs}
      onClick={(e) => enabled && clickCallback(e)}
    >
      {value}
      {children}
    </button>
    {
      // TODO: Refactor the disabled behavior
    }
  </>
)

export default Button
