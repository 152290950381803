import * as Proposal from './styled'
import { ReactNode } from 'react'

export type Props = {
  children?: ReactNode
  className?: string
}
const Footer = ({ children, className }: Props) => {
  return <Proposal.Footer className={className}>{children}</Proposal.Footer>
}

export default Footer
